import {
  CellType,
  LinkedCellDefinition,
  ListComponentCellDefinition,
  StockButtonCellDefinition,
  StockCellDefinition,
} from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { VehicleStatusIcons } from '@/models/vehicle/interfaces'

export const VehicleHeader: LinkedCellDefinition = {
  align: 'center',
  width: 220,
  filterable: true,
  type: CellType.linked,
  options: {
    trigger: Helpers.linkedModel,
    allowCreate: true,
  },
}

export const VehicleStatusHeader: StockCellDefinition = {
  align: 'center',
  filterable: true,
  width: 200,
  type: CellType.stock,
  options: {
    set: VehicleStatusIcons,
  },
}

export const IndicatorHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 110,
  filterable: true,
  sortable: false,
  type: CellType.cellList,
  options: {
    component: CellType.alert,
    cols: 6,
    col: {
      pt: 2,
    },
  },
}

export const MarketPricesHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  sortable: false,
  type: CellType.cellList,
  options: {
    component: CellType.price,
  },
}

export const FileHeader: LinkedCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.linked,
  options: {
    trigger: Helpers.linkedModel,
  },
}

export const StockButtonHeader: StockButtonCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockButton,
  options: {
    trigger: Helpers.linkedModel,
  },
}
